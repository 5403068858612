<template>
  <el-dialog :visible.sync="dialog" :title="isAdd ? '新增短信签名' : '编辑短信签名'" append-to-body width="700px">
    <el-form ref="form" :model="form" :rules="rules" size="small" label-width="120px">
      <el-form-item label="签名编码" prop="signCode">
        <el-input v-model="form.signCode" style="width: 500px;"/>
      </el-form-item>
      <el-form-item label="签名内容" prop="signName">
        <el-input v-model="form.signName" style="width: 500px;"/>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="text" @click="cancel">取消</el-button>
      <el-button :loading="loading" type="primary" @click="doSubmit">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { insertSignInfo, updateSignInfo } from '@/api/sms'
import { parseTime } from '@/utils/index'
export default {
  name: 'Form',
  props: {
    isAdd: {
      type: Boolean,
      required: true
    },
    sup_this: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      dialog: false, loading: false, form: {signId: '',signCode: '', signName: ''},
      rules: {
        signCode: [
          { required: true, message: '请输入签名编码', trigger: 'blur' }
        ],
        signName: [
          { required: true, message: '请输入签名内容', trigger: 'blur' }
        ],
      }
    }
  },
  methods: {
    cancel() {
      this.resetForm()
    },
    doSubmit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.loading = true
          if (this.isAdd) {
            this.doAdd()
          } else {
            this.doEdit()
          } 
        } else {
          return false
        }
      })
    },
    doAdd() {
      this.formData = this.form
      this.params = JSON.stringify(this.formData)
      insertSignInfo(this.params).then(res => {
        this.resetForm()
        this.$notify({
          title: '添加成功',
          type: 'success',
          duration: 2500
        })
        this.loading = false
        this.$parent.$parent.init()
      }).catch(err => {
        this.loading = false
        console.log(err.response.data.message)
      })
    },
    doEdit() {
      this.formData = this.form 
      this.params = JSON.stringify(this.formData)
      updateSignInfo(this.params).then(res => {
        this.resetForm()
        this.$notify({
          title: '修改成功',
          type: 'success',
          duration: 2500
        })
        this.loading = false
        this.sup_this.init()
      }).catch(err => {
        this.loading = false
        console.log(err.response.data.message)
      })
    },
    resetForm() {
      this.dialog = false
      this.$refs['form'].resetFields()
      this.form = { signId: '',signCode: '', signName: ''}
    }
  }
}
</script>

<style scoped>

</style>
