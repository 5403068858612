<template>
  <div class="app-container">
    <eHeader  :query="query"/>
    <!--表格渲染-->
    <el-table v-loading="loading" :data="data" size="small" stripe style="width: 100%;">
      <el-table-column prop="signId" label="ID"/>
      <el-table-column prop="signCode" label="签名编码"/>
      <el-table-column prop="signName" label="签名内容"/>
      <el-table-column label="操作"  align="center">
        <template slot-scope="scope">
          <edit v-if="checkPermission(['ADMIN','MERCHANT_ALL','MERCHANT_EDIT'])" :data="scope.row" :sup_this="sup_this"/>
          <el-popover
            v-if="checkPermission(['ADMIN','USER_ALL','USER_DELETE'])"
            :ref="scope.row.signId"
            placement="top"
            width="180">
            <p>确定删除本条数据吗？</p>
            <div style="text-align: right; margin: 0">
              <el-button size="mini" type="text" @click="$refs[scope.row.signId].doClose()">取消</el-button>
              <el-button :loading="delLoading" type="primary" size="mini" @click="softDelete(scope.row)">确定</el-button>
            </div>
            <el-button slot="reference" type="danger" size="mini">删除</el-button>
          </el-popover>
        </template>
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <el-pagination
      :total="total"
      style="margin-top: 8px;"
      layout="total, prev, pager, next, sizes"
      @size-change="sizeChange"
      @current-change="pageChange"/>
  </div>
</template>

<script>
import checkPermission from '@/utils/permission'
import initData from '@/mixins/initSmsData'
import { parseTime } from '@/utils/index'
import eHeader from '@/components/sms/signinfo/header'
import edit from '@/components/sms/signinfo/edit'
import { updateSignInfo, listSignInfo } from '@/api/sms'
export default {
  components: { eHeader, edit },
  mixins: [initData],
  data() {
    return {
      delLoading: false, sup_this: this,formData:{}
    }
  },
  created() {
    this.$nextTick(() => {
      this.init()
    })
  },
  methods: {
    parseTime,
    checkPermission,
    beforeInit() {
      this.url = '/jrqsms/api/sign/listSignInfo'
      const query = this.query
      const signName = query.signName
      const signCode = query.signCode
      this.formData = { currentPage: this.page, pageSize: this.size }
      if (signName !== '' && signName !== null) { this.formData['signName'] = signName }
      if (signCode !== '' && signCode !== null) { this.formData['signCode'] = signCode } 
      this.params = JSON.stringify(this.formData)
      return true
    },
    softDelete(info) {
      this.delLoading = true
      info.status="1"
      this.formData = info
      this.params = JSON.stringify(this.formData)
      updateSignInfo(this.params).then(res => {
        this.delLoading = false
        this.$refs[info.signId].doClose()
        this.init()
        this.$notify({
          title: '修改成功',
          type: 'success',
          duration: 2500
        })
      }).catch(err => {
        this.delLoading = false
        this.$refs[info.signId].doClose()
        console.log(err.response.data.message)
      })
    }    
  }
}
</script>

<style scoped>

</style>
